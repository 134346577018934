export default function ImageAndDescriptionPortraitCardComponent(props: {
  image: string;
  title: string;
  description: string;
  cardColor: string;
  textColor: string;
  subTextColor: string;
  titleFont: string;
  descriptionFont: string;
}) {
  const parsedCardColor: string[] = props.cardColor.split('-');
  const cardColorHoverDensity: string = (parseInt(parsedCardColor[1]) - 100).toString();
  const cardColorHover: string = parsedCardColor[0] + '-' + cardColorHoverDensity;
  return (
    <div
      className={`max-w-3xl rounded-3xl border border-gray-200 shadow-md bg-${props.cardColor} hover:bg-${cardColorHover}`}>
      <img src={props.image} alt="Card Image" className="rounded-t-3xl" />
      <div className="p-5">
        <h5
          className={`mb-2 text-2xl font-bold tracking-tight text-${props.textColor} text-center ${props.titleFont}`}>
          {props.title}
        </h5>
        <p
          className={`mb-3 font-normal text-${props.subTextColor} text-center ${props.descriptionFont}`}>
          {props.description}
        </p>
      </div>
    </div>
  );
}
