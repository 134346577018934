import linkedin from '@images/linkedin.svg';
import github from '@images/github.svg';
import resume from '@images/resume.png';

export default function SocialMediaComponent() {
  return (
    <div className="flex justify-evenly">
      <a
        href="https://www.linkedin.com/in/miguelsantostankia/"
        target="_blank"
        rel="noopener noreferrer">
        <img src={linkedin}></img>
      </a>
      <a href="https://github.com/miggyst" target="_blank" rel="noopener noreferrer">
        <img src={github}></img>
      </a>
      <a
        href="https://docs.google.com/document/d/1kmZhyacQBNRvV2V1YgaTp8K3i9OAkKXGe0IKmYPhLSA/export?format=pdf"
        rel="noopener noreferrer">
        <img src={resume}></img>
      </a>
    </div>
  );
}
