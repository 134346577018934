import ImageAndDescriptionPortraitCardComponent from '@components/ImageAndDescriptionPortraitCardComponent';
import weddingCake from '@images/weddingcake.jpeg';
export default function ProjectsView() {
  return (
    <div className="flex flex-wrap bg-white justify-center m-3">
      <a href="/projects/wedding">
        <ImageAndDescriptionPortraitCardComponent
          image={weddingCake}
          title="Wedding"
          description="An extension to my website to manage my wedding"
          cardColor="gray-800"
          textColor="black"
          subTextColor="gray-400"
          titleFont="font-serif"
          descriptionFont="font-serif"
        />
      </a>
    </div>
  );
}
