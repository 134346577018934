import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './style.css';
import HomeView from '@views/HomeView';
import ProjectsView from '@views/ProjectsView';
import WeddingView from '@views/projects/wedding/WeddingView';
import ErrorView from '@views/ErrorView';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<HomeView />} />
      <Route path="/" element={<HomeView />} />
      <Route path="/projects">
        <Route index element={<ProjectsView />} />
        <Route path="wedding" element={<WeddingView />} />
      </Route>
      <Route path="*" element={<ErrorView />} />
    </Routes>
  </BrowserRouter>
);
