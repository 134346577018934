import profilePhoto from '@images/profile-photo.jpeg';
import SocialMediaComponent from '@components/SocialMediaComponent';
import HorizontalLineComponent from '@components/HorizontalLineComponent';
import project from '@images/project.png';

export default function HomeView() {
  return (
    <div className="flex flex-wrap bg-white justify-center">
      {/* Image Block */}
      <div className="inline-block p-2">
        <img
          src={profilePhoto}
          alt="Profile Photo"
          className="m-w-fit max-h-[94vh] rounded-full border-8 border-black"></img>
      </div>

      {/* Text Content Block */}
      <div className="flex-1 flex flex-col relative max-h-max justify-center">
        {/* Combined div for Name and Description */}
        <div>
          {/* Name */}
          <h1 className="font-sans landscape:text-[4vw] portrait:text-[6vw] font-medium subpixel-antialiased text-center p-2">
            Alfonso Miguel Santos Tankia
          </h1>
          <HorizontalLineComponent />
          {/* Description */}
          <p className="font-serif landscape:text-[1.8vw] portrait:text-[4vw] font-medium subpixel-antialiased text-center p-2">
            Just your typical developer, living to learn and learning to live. <br />
            Always seeking for new places to explore, taking photos along the way. <br />
            Challenging myself to be a better version of myself day by day. <br />
            Seeking discomfort.
          </p>
        </div>
        {/* Website Quick Links */}
        <br></br>
        <div className="flex justify-evenly">
          <a href="/projects">
            <img src={project} width="80"></img>
          </a>
        </div>
        <br></br>
        {/* Social Media Links */}
        <div className="inset-x-0 bottom-0">
          <SocialMediaComponent />
        </div>
      </div>
    </div>
  );
}
