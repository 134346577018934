import ClassicWeddingBanner from '@images/classicweddingbanner.jpeg';
import GalleryMiranila1 from '@images/gallerymiranila1.jpeg';
import GalleryMiranila2 from '@images/gallerymiranila2.jpeg';
import GalleryMiranila3 from '@images/gallerymiranila3.jpeg';
import ImageAndDescriptionPortraitCardComponent from '@components/ImageAndDescriptionPortraitCardComponent';
import { ChangeEvent } from 'react';
import React from 'react';
import CountNumCSV from '@utils/CountNumCSV';
import ValidateCSV from '@utils/ValidateCSV';
import isBlank from '@utils/ValidateString';

export default class WeddingView extends React.Component<
  Record<string, never>,
  {
    name: string;
    email: string;
    phone: string;
    numPlusOnes: number;
    namesOfPlusOnes: string;
    vehiclePlateNumbers: string;
    showNamesBool: boolean;
    namesClassname: string;
    invalidNames: boolean;
  }
> {
  // eslint-disable-next-line
  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      numPlusOnes: 0,
      namesOfPlusOnes: '',
      vehiclePlateNumbers: '',
      showNamesBool: false,
      namesClassname:
        'border-2 border-red-700 shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
      invalidNames: true
    };

    this.numPlusOnesOnChangeHandler = this.numPlusOnesOnChangeHandler.bind(this);
    this.nameOfPlusOnesOnChangeHandler = this.nameOfPlusOnesOnChangeHandler.bind(this);
    this.validateFormEntry = this.validateFormEntry.bind(this);
    this.nameOnChangeHandler = this.nameOnChangeHandler.bind(this);
    this.emailOnChangeHandler = this.emailOnChangeHandler.bind(this);
  }

  validateFormEntry() {
    if (!isBlank(this.state.name) && !isBlank(this.state.email)) {
      if (this.state.numPlusOnes > 0) {
        if (
          ValidateCSV(this.state.namesOfPlusOnes) &&
          this.state.numPlusOnes == CountNumCSV(this.state.namesOfPlusOnes)
        )
          return true;
        else return false;
      }
      return true;
    }
    return false;
  }

  numPlusOnesOnChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    if (parseInt(event.target.value) > 0) {
      this.setState({ showNamesBool: true, numPlusOnes: parseInt(event.target.value) });
    } else {
      this.setState({ showNamesBool: false, numPlusOnes: 0 });
    }
  }

  nameOfPlusOnesOnChangeHandler(event: ChangeEvent<HTMLTextAreaElement>) {
    if (
      ValidateCSV(event.target.value) &&
      CountNumCSV(event.target.value) == this.state.numPlusOnes
    ) {
      this.setState({
        namesClassname:
          'border shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        invalidNames: false,
        namesOfPlusOnes: event.target.value
      });
    } else {
      this.setState({
        namesClassname:
          'border-2 border-red-700 shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        invalidNames: true,
        namesOfPlusOnes: event.target.value
      });
    }
  }

  nameOnChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      name: event.target.value
    });
  }

  emailOnChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      email: event.target.value
    });
  }

  render() {
    return (
      <div className="flex flex-wrap">
        {/* Landing Banner */}
        <img src={ClassicWeddingBanner} alt="Wedding Banner" className="relative max-h-max" />

        {/* Gallery MiraNila Details */}
        <p className="w-screen text-center pt-4 pb-12 font-serif text-[2vw]">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.theblueleaf.com/gallery-miranila/">
            Location: Gallery MiraNila
          </a>
        </p>
        <div className="flex flex-wrap relative min-w-full justify-evenly">
          <div className="px-6 pb-6">
            <ImageAndDescriptionPortraitCardComponent
              image={GalleryMiranila1}
              title="Date and Time"
              description="January 6, 2024"
              cardColor="gray-800"
              textColor="black"
              subTextColor="gray-700"
              titleFont="font-serif"
              descriptionFont="font-serif"
            />
          </div>
          <div className="px-6 pb-6">
            <ImageAndDescriptionPortraitCardComponent
              image={GalleryMiranila2}
              title="Venue"
              description="26 Mariposa, Quezon City, 1111 Metro Manila, Philippines"
              cardColor="gray-800"
              textColor="black"
              subTextColor="gray-700"
              titleFont="font-serif"
              descriptionFont="font-serif"
            />
          </div>
          <div className="px-6 pb-6">
            <ImageAndDescriptionPortraitCardComponent
              image={GalleryMiranila3}
              title="Parking"
              description="Valet services and general parking spaces are available. Overflow parking available outside the estate"
              cardColor="gray-800"
              textColor="black"
              subTextColor="gray-700"
              titleFont="font-serif"
              descriptionFont="font-serif"
            />
          </div>
        </div>
      </div>
    );
  }
}
